<template>
  <div class="users">
    <HeaderMenu />
    <div class="grid place-items-center min-h-screen">
        <div class="w-1/2 border border-blue-500 p-6">
          <div class="text-left border-b pb-5">
            <h1 class="text-blue-500 text-xl border-b mb-5 text-center">{{ $t('test_device.device_info') }}</h1>
            <div><b>{{ $t('test_device.customer') }}:</b> {{ alarm_info.customers[0].name }}</div>
            <div><b>{{ $t('test_device.unit') }}:</b> {{ alarm_info.units[0].name }}</div>
            <div><b>{{ $t('test_device.name') }}:</b> {{ alarm_info.device.device_name }}</div>
            <!-- <div><b>{{ $t('test_device.model') }}:</b> {{ alarm_info.device.model }}</div> -->
            <div><b>{{ $t('test_device.phone') }}:</b> {{ alarm_info.device.phone }}</div>
            <!-- <div><b>{{ $t('test_device.imei') }}:</b> {{ alarm_info.device.imei }}</div> -->
            <div><b>{{ $t('test_device.last_seen') }}:</b> {{ formatTime(alarm_info.gps.updatedAt) }}</div>
            <div><b>{{ $t('test_device.last_test_time') }}:</b> {{ formatDate(alarm_info.device.device_last_test_time) }}</div>
          </div>
          <div v-if="!isUnitHasModule">
            <p class="text-center text-red-400 mt-5">
              {{ $t('test_device.no_module_message') }}
            </p>
          </div>
          <div class="mt-6" v-if="!shouldHideButton && isUnitHasModule">
            <div >
              <h2 class="font-bold text-lg">{{ $t('test_device.important_things_title') }}:</h2>
              <ol class="list-decimal mt-3 pl-0 ml-6">
                <li>{{ $t('test_device.important_things_1') }}</li>
                <li>{{ $t('test_device.important_things_2') }}</li>
                <li>{{ $t('test_device.important_things_3') }}</li>
                <li>{{ $t('test_device.important_things_4') }}</li>
                <li>{{ $t('test_device.important_things_5') }}</li>
                <li>{{ $t('test_device.important_things_6') }}</li>
              </ol>
            </div>
            <div class="text-center mt-5">
              <button class="btn-blue text-xl p-3" @click="showAutoTestModal()">{{$t('test_device.button_text')}}</button>
              <button v-tooltip="tooltipData" class="border border-gray-400 p-4 ml-3 px-5 text-center rounded-full hover:text-white hover:bg-blue-500 transition v-popper">
                <BaseIcon icon="question" />
              </button>
            </div>
          </div>
        </div>
     </div>
     <AlarmAutoTest ref="AutoProvlarm" />
  </div>
</template>

<script>
import HeaderMenu from "@/components/layouts/header_menu";
import AlarmAutoTest from "@/components/modal/alarm_auto_test";
import 'floating-vue/dist/style.css'
//import tooltip from "@/components/base/tooltip";
export default {
  title() {
    return this.$t("page_titles.alarm_view");
  },
   components: {
      HeaderMenu,
      AlarmAutoTest
  },
  data() {
    return {
      showButton: true,
      tooltipData: 'Automatiskt provlarm, är en funktion där varje individ i er verksamhet kan provlarma sitt larm automatiskt. Funktionen bygger på att man utan förvarning till larmcentral kan prova sitt larm och verifiera vitala funktioner såsom "GPS-positionering", "Tvåvägsfunktion", "Larmplan" och "Larmkedja". Automatiskt provlarm, underlättar ert användande och möjliggör funktionstest av ert larm dygnet runt årets alla dagar. För att genomföra ett automatiskt provlarm fyller du i det tomma fälten. För att aktivera funktionen måste du uppge larmets telematiknummer (oftast 13 siffror) följt av er unika behörighetskod. Efter du aktiverat det automatiska provlarmet kan du själv trycka på larmknappen (3 sekunder). Därefter kommer din egna mobiltelefon agera "larmcentral, bli uppringd och få en kvittens på att larmet är genomfört följt av en kartlänk för att validera gps-positionen. Ett automatiskt provlarm kopplar ifrån alla tilläggsfunktioner ni kan tänkas ha i er verksamhet såsom "larmdisplayer", "sms" och "mejlkontakter". Du behöver således inte informera övriga personer i verksamheten att du ska genomföra ett provlarm, utan kan efter eget bevåg välja att prova ditt personlarm.',
      hidePatterns: [
        "SRT330", 
        "SRT334",
        "SRT430",
        "SRT278",
        "SRT341",
        "TWIG Beacon",
        "TWIG Beacon EX",
        /TELTONIKA/,  // Regex pattern
        "4G Box",
        "Anchor Beacon",
        "Avigilon Alta Compact Dome 5MP",
        /^AJAX/       // Regex pattern for starts-with match
      ],
      tooltipOptions: {
        content: this.tooltipData,
        classes: 'custom-tooltip', // Add custom class
        delay: { show: 100, hide: 0 }
      }
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    alarm_info() {
      return this.$store.getters.getAutoProvlarm;
    },
    shouldHideButton() {
      return this.hidePatterns.some(pattern => {
        if (typeof pattern === "string") {
          return pattern === this.alarm_info.device.model;
        }
        if (pattern instanceof RegExp) {
          return pattern.test(this.alarm_info.device.model);
        }
        return false;
      });
    },
    isUnitHasModule() {
      return this.alarm_info?.units?.[0]?.addons?.includes("alarm_reminder");
    }
  },

  methods: {
    showAutoTestModal() {
      //let selectedAlarm = this.alarms.find((alarm) => alarm.imei_number === imeiToFind);
      let deviceData = this.$store.getters.getAutoProvlarm

      //console.log("deviceData:", deviceData);

      let alarm_info = {
        imei_number: deviceData.device.imei,
        unit_name: deviceData.device.device_name,
        // test_sms_number: deviceData.device.phone,
        last_tunnel_disable: deviceData.device.last_tunnel_disable,
        device_info: deviceData
      }

      
      this.$refs.AutoProvlarm.setAlarmInfo(alarm_info);
      this.$modal.show("alarm-auto-test");
    },
    formatDate(date) {
      if (date == null) return "-";
      else return this.moment(date).format("YYYY-MM-DD, HH:m");
    },
    formatTime(date) {
      const now = new Date();
      const targetDate = new Date(date);
      const diffInSeconds = Math.floor((now - targetDate) / 1000);

      if (diffInSeconds < 60) {
        return "a few seconds ago";
        } else if (diffInSeconds < 3600) {
            const minutes = Math.floor(diffInSeconds / 60);
            return minutes === 1 ? "a minute ago" : `${minutes} minutes ago`;
        } else if (diffInSeconds < 86400) {
            const hours = Math.floor(diffInSeconds / 3600);
            return hours === 1 ? "an hour ago" : `${hours} hours ago`;
        } else if (diffInSeconds < 604800) {
            const days = Math.floor(diffInSeconds / 86400);
            return days === 1 ? "a day ago" : `${days} days ago`;
        } else if (diffInSeconds < 2592000) {
            const weeks = Math.floor(diffInSeconds / 604800);
            return weeks === 1 ? "a week ago" : `${weeks} weeks ago`;
        } else if (diffInSeconds < 31536000) {
            const months = Math.floor(diffInSeconds / 2592000);
            return months === 1 ? "a month ago" : `${months} months ago`;
        } else {
            const years = Math.floor(diffInSeconds / 31536000);
            return years === 1 ? "a year ago" : `${years} years ago`;
        }

    },
  },

  created() {
  },
  watch: {
    "$i18n.locale"() {
      document.title = this.$t("page_titles.test_alarm");
      //this.setPageTitle(`${this.unit.name} - ${this.$t("top_titles.alarm_view")}`, "alarms");
    },
  },
};
</script>

<style>

.v-popper__popper[data-popper-placement^='top'] .v-popper__arrow-inner,
.v-popper__popper[data-popper-placement^='top'] .v-popper__arrow-outer {
    &::before {
        rotate: 45deg;
        left: -6px;
        top: -12px;
        translate: 0 -1px;
    }

    width: 50%;
}
.v-popper__popper[data-popper-placement^='left'] .v-popper__arrow-inner,
.v-popper__popper[data-popper-placement^='left'] .v-popper__arrow-outer {
    &::before {
        rotate: -45deg;
        left: -12px;
        top: -6px;
        translate: -1px 0;
    }
}
.v-popper__popper[data-popper-placement^='bottom'] .v-popper__arrow-inner,
.v-popper__popper[data-popper-placement^='bottom'] .v-popper__arrow-outer {
    &::before {
        rotate: -135deg;
        left: -6px;
        top: 0;
        translate: 0 1px;
    }
}
.v-popper__popper[data-popper-placement^='right'] .v-popper__arrow-inner,
.v-popper__popper[data-popper-placement^='right'] .v-popper__arrow-outer {
    &::before {
        rotate: 135deg;
        left: 0;
        top: -6px;
        translate: 1px 0;
    }
}
.v-popper--theme-tooltip {
    .v-popper__inner {
        border: 1px solid #000;
        background: #fff;
        width: 40%;
        left: 50%;
        padding: 20px !important;
        transform: translateX(-50%);
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        color: #12b43b !important;
    }
    .v-popper__arrow-outer {
        &::before {
            content: '';
            width: 12px;
            height: 12px;
            border: 1px solid red;
            background: rgb(92, 45, 44);
            clip-path: polygon(100% 0, 0% 100%, 100% 100%);
        }
    }
}

</style>